// 縦横比classを付与
export const addAspectRatioClass = () => {
  const $targets = document.querySelectorAll("[data-aspect-ratio]");
  // 正方形と認識させる比率
  const magnification = 0.85;

  $targets.forEach(($target) => {
    // 読み込み監視専用インスタンス
    const img = new Image();
    img.src = $target.src;
    img.onload = () => {
      const targetWidth = $target.width;
      const targetHeight = $target.height;
      if (targetWidth < targetHeight) {
        // 正方形に近い場合、正方形のクラスをつける
        if (targetHeight * magnification < targetWidth) {
          $target.classList.add("js-aspect-ratio--square");
          return false;
        }
        $target.classList.add("js-aspect-ratio--portrait");
      } else if (targetWidth > targetHeight) {
        // 正方形に近い場合、正方形のクラスをつける
        if (targetWidth * magnification < targetHeight) {
          $target.classList.add("js-aspect-ratio--square");
          return false;
        }
        $target.classList.add("js-aspect-ratio--landscape");
      } else {
        $target.classList.add("js-aspect-ratio--square");
      }
    };
  });
};
