import anime from "animejs/lib/anime.es.js";

// 縮小アニメーション
export const scaleDownAnimation = () => {
  anime({
    targets: "[data-scale-down-animation]",
    delay: 0,
    duration: 2500,
    easing: "easeInOutQuad",
    scale: [1.05, 1],
  });
};
