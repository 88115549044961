"use strict";

import barba from "@barba/core";
import barbaPrefetch from "@barba/prefetch";
import anime from "animejs/lib/anime.es.js";

// コンポーネント
import { passingAnimation } from "./components/passing-animation";
import { drawerFunction } from "./components/drawer";
import { clippingText } from "./components/clipping-text";
import { scaleDownAnimation } from "./components/scale-down-animation";
import { emptyCheck } from "./components/empty-check";
import { textAccordion } from "./components/text-accordion";
import { addAspectRatioClass } from "./components/add-aspect-ratio-class";
import { setVh } from "./components/set-vh";
import { setCustomProperty } from "./components/set-custom-property";
import { webFontLoading } from "./components/web-font";
import { adOverlayRemove } from "./components/add-overlay-remove";

{
  // head内のmetaタグ書き換え
  const replaceHead = function (data) {
    const head = document.head;
    const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
    const newPageHead = document.createElement("head");
    newPageHead.innerHTML = newPageRawHead;

    const removeHeadTags = ["meta[name='keywords']", "meta[name='description']", "meta[property^='og']", "meta[name^='twitter']", "meta[itemprop]", "link[itemprop]", "link[rel='prev']", "link[rel='next']", "link[rel='canonical']"].join(",");

    const headTags = head.querySelectorAll(removeHeadTags);

    for (let i = 0; i < headTags.length; i++) {
      head.removeChild(headTags[i]);
    }

    const newHeadTags = newPageHead.querySelectorAll(removeHeadTags);

    for (let i = 0; i < newHeadTags.length; i++) {
      head.appendChild(newHeadTags[i]);
    }
  };

  // GoogleAnalyticsの書き換え
  barba.hooks.after(() => {
    if (typeof gtag === "function") {
      // inkrich と ユーザー独自のプロパティが設定される前提
      gtag("config", window.GA_INKRICH_ID, {
        page_path: window.location.pathname,
      });
      // GoogleAnalytics を設定しないユーザーもいるので設定していたら gtag を追加する
      if (window.GA_USER_ID) {
        gtag("config", window.GA_USER_ID, {
          page_path: window.location.pathname,
        });
      }
    }
  });

  // ページが変わったときのスクロール処理
  const pageChangedScrollPosition = () => {
    if (location.hash) {
      // #の位置で表示
      const scrollEl = window.document.scrollingElement || window.document.body || window.document.documentElement;
      const el = document.getElementById(location.hash.split("#")[1]);
      anime({
        targets: scrollEl,
        scrollTop: el.offsetTop,
        delay: 0,
        duration: 0,
      });
    } else {
      window.scrollTo(0, 0);
    }
  };

  // 下層メインビジュアルのアニメーション
  const gMainVisualAnime = ($parent) => {
    const $targetImage = $parent.querySelector(".g-main-visual__image");

    anime({
      targets: $targetImage,
      delay: 250,
      duration: 1200,
      easing: "easeOutQuad",
      scale: [1.03, 1],
    });
  };

  // barba.jsの処理
  const barbaStart = () => {
    // 画面内のリンクを取得
    barba.use(barbaPrefetch);

    barba.init({
      // ページ遷移が完了するまで別ページへの遷移をキャンセル
      preventRunning: true,
      // ページ遷移フック
      transitions: [
        {
          name: "default-transition",
          enter(data) {
            const done = this.async();
            replaceHead(data);
            pageChanged(data.next.container);
            pageChangedScrollPosition();
            if (data.next.container.dataset.barbaNamespace === "top") {
              // TOPへ遷移する処理
            } else {
              // 下層へ遷移する処理
              gMainVisualAnime(data.next.container);
            }
            done();
          },
        },
      ],
    });
  };

  // 初回読み込み時のみ発火
  const pageLoaded = () => {
    barbaStart();
    webFontLoading();
    drawerFunction();
    gMainVisualAnime(document);
  };

  // ページ遷移のたび発火
  const pageChanged = ($barbaContainer) => {
    passingAnimation();
    scaleDownAnimation();
    clippingText();
    emptyCheck();
    textAccordion();
    addAspectRatioClass();
    setVh();
    setCustomProperty();
    adOverlayRemove($barbaContainer);
  };

  window.addEventListener("DOMContentLoaded", () => {
    pageLoaded();
    pageChanged();
  });
}

// TODO: 現状の問題点
// webfontの読み込みが終わるのを待つかどうか、ページ切替時にFOUTが起きている
// barba.jsをコンポーネント化するか
// 下層からTOPに遷移したときのチラツキ
