import anime from "animejs/lib/anime.es.js";

export const passingAnimation = () => {
  const $targets = document.querySelectorAll("[data-passing-animation]");
  $targets.forEach(($target) => {
    $target.innerHTML = `<div class="js-passing-animation" style="color: transparent;">${$target.innerHTML}<div class="js-passing-animation__el"></div></div>`;
    const $targetWrap = $target.querySelector(".js-passing-animation");
    const $targetEl = $target.querySelector(".js-passing-animation__el");

    anime
      .timeline({
        easing: "easeInOutCirc",
      })
      .add({
        targets: $targetEl,
        delay: 1200,
        duration: 250,
        translateX: ["-100%", 0],
        complete: () => {
          $targetWrap.removeAttribute("style");
        },
      })
      .add({
        delay: 500,
        duration: 250,
        targets: $targetEl,
        translateX: [0, "100%"],
        complete: () => {
          $targetEl.remove();
        },
      });
  });
};
