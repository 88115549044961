// 子要素の有無をチェック
export const emptyCheck = () => {
  const $items = document.querySelectorAll("[data-empty-check]");
  const config = {
    childList: true,
  };

  $items.forEach(($item) => {
    // devtool上でも反映する
    const observer = new MutationObserver(function () {
      if ($item.children.length === 0) {
        $item.classList.add("js-empty");
      } else {
        $item.classList.remove("js-empty");
      }
    });
    observer.observe($item, config);

    if ($item.children.length === 0) {
      $item.classList.add("js-empty");
    }
  });
};
