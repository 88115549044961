import barba from "@barba/core";
import anime from "animejs/lib/anime.es.js";

// ドロワークラス
const CLASS_HIDE_DRAWER = "l-header__menu--hide";

// bodyスクロール固定関連
const FIXED_BODY_NAME = "js-scroll-fixed";
let fixedBodyScrollPosition;

// body固定
const fixedBody = () => {
  fixedBodyScrollPosition = window.scrollY;
  document.body.classList.add(FIXED_BODY_NAME);
  document.body.style.top = `-${fixedBodyScrollPosition}px`;
  return false;
};

// body固定解除
const unFixedBody = () => {
  document.body.classList.remove(FIXED_BODY_NAME);
  document.body.style.top = 0;
  window.scrollTo(0, fixedBodyScrollPosition);
  return false;
};

// sticky固定
const fixedSticky = (fixedBodyClass) => {
  const $target = document.querySelector(".p-history__sticky");
  if ($target == null) {
    return false;
  }
  const $parent = $target.parentNode;

  $target.removeAttribute("style");
  $parent.removeAttribute("style");

  if (document.body.classList.contains(fixedBodyClass)) {
    return false;
  }

  // 高さを固定するため親への指定が先
  $parent.style.cssText = `width: ${$parent.clientWidth}px; height: ${$parent.clientHeight}px`;
  $target.style.cssText = `position: fixed; top: ${$target.getBoundingClientRect().top}px;`;
};

// ドロワーオープンアニメーション
const drawerOpenAnimation = () => {
  const $menu = document.querySelector('[data-drawer="menu"]');

  fixedSticky(FIXED_BODY_NAME);
  fixedBody();

  anime
    .timeline({
      delay: 0,
      easing: "easeInOutQuad",
    })
    .add({
      targets: '[data-drawer="open"]',
      duration: 250,
      opacity: [1, 0],
      complete: () => {
        $menu.classList.remove(CLASS_HIDE_DRAWER);
        $menu.scrollTop = 0;
      },
    })
    .add({
      targets: '[data-drawer="menu"]',
      duration: 250,
      opacity: [0, 1],
    })
    .add({
      targets: ".l-header__item",
      duration: 250,
      opacity: [0, 1],
      translateY: [0, "5px"],
      delay: anime.stagger(50),
    });
};

// ドロワークローズアニメーション
const drawerCloseAnimation = (callback, value) => {
  fixedSticky(FIXED_BODY_NAME);
  unFixedBody();

  anime
    .timeline({
      delay: 0,
      duration: 250,
      easing: "easeInOutQuad",
    })
    .add({
      targets: '[data-drawer="menu"]',
      opacity: [1, 0],
      complete: () => {
        document.querySelector('[data-drawer="menu"]').classList.add(CLASS_HIDE_DRAWER);
      },
    })
    .add({
      targets: '[data-drawer="open"]',
      opacity: [0, 1],
      begin: () => {
        if (typeof callback !== "undefined") {
          callback(value);
        }
      },
    });
};

// アンカー移動アニメーション
const anchorTransition = (anchorLink) => {
  const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
  const el = document.getElementById(`${anchorLink}`);

  anime({
    targets: scrollElement,
    scrollTop: el.offsetTop,
    duration: 0,
    easing: "easeInOutQuad",
    begin: () => {
      history.replaceState("", "", `${location.pathname}#${anchorLink}`);
    },
  });
};

// ドロワー処理
export const drawerFunction = () => {
  const $drawer = document.querySelector('[data-drawer="menu"]');
  const $openButton = document.querySelector('[data-drawer="open"]');
  const $closeButton = document.querySelector('[data-drawer="close"]');

  const pageTransition = ($this) => {
    const url = $this.getAttribute("href");
    if (url !== "") {
      // window.location = url;
      // 上記のbarba.js ver
      barba.go(url);
    }
  };

  $openButton.addEventListener("click", drawerOpenAnimation);
  $drawer.addEventListener("click", () => {
    $closeButton.click();
  });
  $closeButton.addEventListener("click", (e) => {
    e.stopPropagation();
    drawerCloseAnimation();
  });

  // リンク処理
  $drawer.querySelectorAll("a").forEach(($item) => {
    $item.addEventListener("click", (e) => {
      const url = $item.getAttribute("href");

      e.preventDefault();
      e.stopPropagation();

      // 検証用フルパス非対応
      if (document.getElementById("top") && url.indexOf("#") > -1) {
        const splitTargetUrl = $item.getAttribute("href").split("#");
        const ANCHOR_LINK = splitTargetUrl[1];
        drawerCloseAnimation(anchorTransition, ANCHOR_LINK);
        return false;
      }

      // フルパス対応
      // if (document.getElementById('top') && url.indexOf('#') > -1 && url.indexOf(document.domain) > -1) {
      //   const splitTargetUrl = $item.getAttribute('href').split('#');
      //   const ANCHOR_LINK = splitTargetUrl[1];
      //   drawerCloseAnimation(anchorTransition, ANCHOR_LINK);
      //   return false
      // }

      // hashchangeでの処理も候補

      drawerCloseAnimation(pageTransition, $item);
    });
  });
};
